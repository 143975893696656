// app.js
"use strict";

import angular from "angular";
import "angular-i18n/angular-locale_es-es";
import "@uirouter/angularjs";
import "oclazyload";
import "bootstrap/dist/css/bootstrap.min.css";
import "./core/core.less";

import { configHttp } from "./core/config.http";
import { appRouting } from "./app.routing";
import { scroll } from "./app.scrollToTop.run";
import { COOKIES_MODULE } from "./components/cookies/cookies.module";


const AGENTES_LOGIN_SWENO_ENERGIA =  angular
    .module("sweno-energy.agent-login", [
        "ui.router",
        "oc.lazyLoad",
        COOKIES_MODULE
    ])
    .config(appRouting)
    .config(configHttp)
    .run(scroll)
    .name
    ;

export { AGENTES_LOGIN_SWENO_ENERGIA };
