// app.routing.js

"use strict";

import {loginWrapper, login, loginCentroTrabajo, agenteGenerarPass} from "./app.states";

appRouting.$inject = ["$stateProvider", "$urlRouterProvider", "$locationProvider", "$urlMatcherFactoryProvider"];

function appRouting($stateProvider, $urlRouterProvider, $locationProvider, $urlMatcherFactoryProvider) {
  $urlMatcherFactoryProvider.strictMode(false);
  $urlRouterProvider.otherwise("/login");
  $locationProvider.html5Mode(true);
  $stateProvider.state(loginWrapper);
  $stateProvider.state(login);
  $stateProvider.state(loginCentroTrabajo);
  $stateProvider.state(agenteGenerarPass);
}

export { appRouting };
