// app.states.js

"use strict";

const loginWrapper = {
  name: "loginWrapper",
  url: "/login",
  component: "loginWrapper",
  redirectTo: "loginWrapper.login",
  lazyLoad: ($transition$) => {
    const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
    return import(/* webpackChunkName: "login-wrapper.module" */ "./components/login-wrapper/login-wrapper.module")
      .then(mod => $ocLazyLoad.inject(mod.LOGIN_WRAPPER_MODULE))
      .catch(err => {
        throw new Error("Ooops, something went wrong, " + err);
      });
  },
  onEnter: ["$document", ($document) => {
    $document[0].title = "Login";
    $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Ya eres cliente de Energia El Corte Ingles');
    $document[0].querySelector('meta[name="keyword"]').setAttribute('content', '');
    $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://contratacion.energiaelcorteingles.es/login');
  }]
};

const login = {
  name: "loginWrapper.login",
  url: "/",
  component: "login",
  lazyLoad: ($transition$) => {
    const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
    return import(/* webpackChunkName: "login.module" */ "./components/login-wrapper/login/login.module")
      .then(mod => $ocLazyLoad.inject(mod.LOGIN_MODULE))
      .catch(err => {
        throw new Error("Ooops, something went wrong, " + err);
      });
  },
  onEnter: ["$document", ($document) => {
    $document[0].title = "Login";
    $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Ya eres cliente de Energia El Corte Ingles');
    $document[0].querySelector('meta[name="keyword"]').setAttribute('content', '');
    $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://contratacion.energiaelcorteingles.es/login');
  }]
};

const loginCentroTrabajo = {
  name: "loginWrapper.centroTrabajo",
  url: "/centro-trabajo",
  component: "centroTrabajo",
  lazyLoad: ($transition$) => {
    const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
    return import(/* webpackChunkName: "centro-trabajo.module" */ "./components/login-wrapper/centro-trabajo/centro-trabajo.module")
      .then(mod => $ocLazyLoad.inject(mod.CENTRO_TRABAJO_COMPONENT))
      .catch(err => {
        throw new Error("Ooops, something went wrong, " + err);
      });
  },
  onEnter: ["$document", ($document) => {
    $document[0].title = "Login - Centro Trabajo";
    $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Ya eres cliente de Energia El Corte Ingles');
    $document[0].querySelector('meta[name="keyword"]').setAttribute('content', '');
    $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://contratacion.energiaelcorteingles.es/login/centro-trabajo');
  }]
};

const agenteGenerarPass = {
  name: "loginWrapper.generarPass",
  url: "/generarPass?token=",
  component: "generarPass",
  lazyLoad: ($transition$) => {
    const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
    return import(/* webpackChunkName: "acceso.module" */ "./components/login-wrapper/generar-pass/generar-pass.module")
      .then(mod => $ocLazyLoad.inject(mod.GENERAR_PASS_MODULE))
      .catch(err => {
        throw new Error("Ooops, something went wrong, " + err);
      });
  },
  onEnter: ["$document", ($document) => {
    $document[0].title = "Contratación - Generar Password";
    $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Contratación de Energia El Corte Ingles');
    $document[0].querySelector('meta[name="keyword"]').setAttribute('content', 'Energia El Corte Ingles agentes, Agentes Energia El Corte Ingles, Area de contratación Energia El Corte Ingles');
    $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://contratacion.energiaelcorteingles.es/login/generar-pass');
    $document[0].querySelector('script[type="application/ld+json"]').innerText = '{"@context":"https://schema.org/","@id":"https://contratacion.energiaelcorteingles.es/login","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"home","item":"https://energiaelcorteingles.es"},{"@type":"ListItem","position":2,"name":"loginWrapper","item":"https://contratacion.energiaelcorteingles.es/login"}]}';
  }]
};

export { loginWrapper, login, loginCentroTrabajo, agenteGenerarPass };
